import React from 'react';

export default function NotFound(props){

	return (
		<div className={"NotFound "+ props.size }>
			<h1>Page Not Found</h1>
		</div>
	);

}